import request from '../request/http'

const prefix = '/api/v1'

//更新机构课酬状态
export const tempUpdateCompanyLessonStatus = (params) => {
    return request({
        url: `${prefix}/lesson/lessonTool/tempUpdateCompanyLessonStatus`,
        method: 'post',
        data: params,
    })
}

export const account = (params) => {
    return request({
        url: `${prefix}/company/account`,
        method: 'get',
        params: params,
    })
}

//管理员标签接口  
export const followUpTagList = (params) => {
    return request({
        url: `${prefix}/grade/followUp/tag/list`,
        method: 'get',
        params: params,
    })
}

//管理员标签接口新增
export const followUpTagAdd = (params) => {
    return request({
        url: `${prefix}/grade/followUp/tag`,
        method: 'post',
        data: params,
    })
}

//管理员标签接口新增
export const followUpTagUp = (params) => {
    return request({
        url: `${prefix}/grade/followUp/tag`,
        method: 'put',
        data: params,
    })
}

// 查询未读消息数
export const getUserStudentUnreadMsg = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/getUserStudentUnreadMsg`,
        method: 'post',
        data: params,
    })
}
// 读消息 
export const readMsg = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/readMsg`,
        method: 'post',
        data: params,
    })
}
// 账单学生列表
export const getOrderStudentDataView = (params) => {
    return request({
        url: `${prefix}/company/service/order/getOrderStudentDataView`,
        method: 'post',
        data: params,
    })
}
//代找账单查看数据视图接口 
export const dataViewByMatchOrder = (params) => {
    return request({
        url: `${prefix}/company/service/order/dataViewByMatchOrder`,
        method: 'post',
        data: params,
    })
}
//代找账单创建接口 
export const byMatchOrder = (params) => {
    return request({
        url: `${prefix}/company/service/order/byMatchOrder`,
        method: 'post',
        data: params,
    })
}

// 账单学生列表导出
export const getOrderStudentExportXlsx = (params) => {
    return request({
        url: `${prefix}/company/service/order/getOrderStudentExportXlsx`,
        method: 'post',
        data: params,
    })
}
// 代找订单导出
export const dataExportXlsxByMatchOrder = (params) => {
    return request({
        url: `${prefix}/company/service/order/dataExportXlsxByMatchOrder`,
        method: 'post',
        data: params,
    })
}


// 登记
export const dispatchLessonLogAdd = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatchLessonLog`,
        method: 'post',
        data: params,
    })
}



// 登记修改/company/teacher/dispatchLessonLog
export const dispatchLessonLogUp = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatchLessonLog`,
        method: 'put',
        data: params,
    })
}

// 删除
export const dispatchLessonLogDel = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatchLessonLog`,
        method: 'delete',
        data: params,
    })
}


// 报录查询
export const collegeMajorRecruit = (params) => {
    return request({
        url: `${prefix}/common/collegeMajorRecruit/list`,
        method: 'get',
        params: params,
    })
}

// 报考查询新增
export const collegeMajorRecruitAdd = (params) => {
    return request({
        url: `${prefix}/common/collegeMajorRecruit`,
        method: 'post',
        data: params,
    })
}

// 报考查询修改
export const collegeMajorRecruitPut = (params) => {
    return request({
        url: `${prefix}/common/collegeMajorRecruit`,
        method: 'put',
        data: params,
    })
}

// 报考查询详情
export const collegeMajorRecruitDetail = (params) => {
    return request({
        url: `${prefix}/common/collegeMajorRecruit`,
        method: 'get',
        params: params,
    })
}

// 批量删除
export const collegeMajorRecruitDel = (params) => {
    return request({
        url: `${prefix}/common/collegeMajorRecruit/list`,
        method: 'delete',
        data: params,
    })
}





export const collegeMajorStatistic = (params) => {
    return request({
        url: `${prefix}/teacher/collegeMajorStatistic/list`,
        method: 'get',
        params: params,
    })
}

export const collegeInfo = (params) => {
    return request({
        url: `${prefix}/college/info`,
        method: 'get',
        params: params,
    })
}
//共享活动管理查询列表
export const activityList = (params) => {
    return request({
        url: `${prefix}/sharePlatform/activity`,
        method: 'post',
        data: params,
    })
}

// 通过
export const checkAccess = (params) => {
    return request({
        url: `${prefix}/company/service/order/checkAccess`,
        method: 'post',
        data: params,
    })
}

// 驳回
export const checkRefuse = (params) => {
    return request({
        url: `${prefix}/company/service/order/checkRefuse`,
        method: 'post',
        data: params,
    })
}

// 机构账单详情
export const getOrderFeeDetail = (params) => {
    return request({
        url: `${prefix}/company/service/order/getOrderFeeDetail`,
        method: 'post',
        data: params,
    })
}

// 机构账单创建
export const orderFee = (params) => {
    return request({
        url: `${prefix}/company/service/order`,
        method: 'post',
        data: params,
    })
}
// 代找账单获取费用明细接口 
export const feeDetailByMatchOrder = (params) => {
    return request({
        url: `${prefix}/company/service/order/feeDetailByMatchOrder`,
        method: 'post',
        data: params,
    })
}

/**
 * 提前查看
 * @param {*} params
 */
 export const handPull = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageTask/handPull`,
        method: 'post',
        data: params,
    })
}


/**
 * 收款记录导出
 * @param {*} params
 */
 export const findOrderExport = (params) => {
    return request({
        url: `${prefix}/sharePlatform/findOrder/export`,
        method: 'get',
        params: params,
    })
}

/**
 * 收款记录导出
 * @param {*} params
 */
export const orderReceiptRecordExport = (params) => {
    return request({
        url: `${prefix}/company/orderReceiptRecord/export`,
        method: 'POST',
        data: params,
    })
}

/**
 * 订单匹配导出
 * @param {*} params
 */
export const orderExport = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/export`,
        method: 'get',
        params: params,
    })
}


/**
 * 查询订单收款记录
 * @param {*} params
 */
export const selectList = (params) => {
    return request({
        url: `${prefix}/company/orderReceiptRecord/selectList`,
        method: 'post',
        data: params,
    })
}

/**
 * 创建订单收款记录
 * @param {*} params
 */
export const orderReceiptRecordAdd = (params) => {
    return request({
        url: `${prefix}/company/orderReceiptRecord`,
        method: 'post',
        data: params,
    })
}

/**
 * 修改订单收款记录
 * @param {*} params
 */
export const orderReceiptRecordUp = (params) => {
    return request({
        url: `${prefix}/company/orderReceiptRecord`,
        method: 'put',
        data: params,
    })
}

/**
 * 删除订单收款记录
 * @param {*} params
 */
export const orderReceiptRecordDel = (params) => {
    return request({
        url: `${prefix}/company/orderReceiptRecord`,
        method: 'delete',
        data: params,
    })
}

export const orderReadCompanyUser = (params) => {
    return request({
        url: `${prefix}/sharePlatform/tool/orderReadCompanyUser/list`,
        method: 'get',
        params: params,
    })
}
/**
 * 院校资料新增
 * @param {*} params
 */
export const commonCollegeMaterialAdd = (params) => {
    return request({
        url: `${prefix}/common/commonCollegeMaterial`,
        method: 'post',
        data: params,
    })
}
/**
 * 院校资料修改
 * @param {*} params
 */
export const commonCollegeMaterialUp = (params) => {
    return request({
        url: `${prefix}/common/commonCollegeMaterial`,
        method: 'put',
        data: params,
    })
}

/**
 * 院校资料删除
 * @param {*} params
 */
export const commonCollegeMaterialDel = (params) => {
    return request({
        url: `${prefix}/common/commonCollegeMaterial`,
        method: 'delete',
        data: params,
    })
}
/**
 * 院校资料批量删除
 * @param {*} params
 */
export const commonCollegeMaterialDelList = (params) => {
    return request({
        url: `${prefix}/common/commonCollegeMaterial/list`,
        method: 'delete',
        data: params,
    })
}
/**
 * 院校资料查询
 * @param {*} params
 */
export const commonCollegeMaterial = (params) => {
    return request({
        url: `${prefix}/common/commonCollegeMaterial/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 学管师任务
 * @param {*} params
 */
export const doTask = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageTask/doTask`,
        method: 'post',
        data: params,
    })
}


/**
 * 学管师查询
 * @param {*} params
 */
export const learnManageTask = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageTask/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 学管师统计
 * @param {*} params
 */
export const statistic = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageTask/statistic`,
        method: 'get',
        data: params,
    })
}


// 批量审核计划

export const checkList = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/check/list`,
        method: 'post',
        data: params,
    })
}
//批量取消
export const  courseCancle = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/list`,
        method: 'put',
        data: params,
    })
}

//批量调整
export const  courseTimeDelay = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/bulkStartTimeDelay`,
        method: 'post',
        data: params,
    })
}
// 获取最优匹配师接口

export const getBestAllocationManage = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/getBestAllocationManage`,
        method: 'post',
        data: params,
    })
}


/**
 * 计划组详情
 * @param {*} params
 */
export const coursePlanGroupDetail = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlanGroup`,
        method: 'get',
        params: params,
    })
}

/**
 * 创建计划 
 * @param {*} params
 */
export const byTeacher = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/bulk/byTeacher`,
        method: 'post',
        data: params,
    })
}

/**
 * 查询计划组 
 * @param {*} params
 */
export const coursePlanGroupList = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlanGroup/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 创建计划组
 * @param {*} params
 */

export const coursePlanGroup = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlanGroup`,
        method: 'post',
        data: params,
    })
}

/**
 * 修改计划组
 * @param {*} params
 */

export const upCoursePlanGroup = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlanGroup`,
        method: 'put',
        data: params,
    })
}

/**
 * 删除计划组
 * @param {*} params
 */

export const delCoursePlanGroup = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlanGroup`,
        method: 'delete',
        data: params,
    })
}

/**
 * 老师匹配订单修改
 * @param {*} params
 */

export const teacherAllocationOrder = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order`,
        method: 'put',
        data: params,
    })
}

/**
 * 学管师提交
 * @param {*} params
 */

export const flexLessonLog = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/flexLessonLog`,
        method: 'post',
        data: params,
    })
}

/**
 * 取消订单
 * @param {*} params
 */
export const orderCancel = (params) => {
    return request({
        url: `${prefix}/sharePlatform/findOrderHandle/order/cancel`,
        method: 'post',
        data: params,
    })
}

/**
 * 发送短信验证码
 * @param {*} params
 */
export const smscode = (params) => {
    return request({
        url: `${prefix}/send/smscode`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户登录(短信验证码) 
 * @param {*} params
 */
export const userlogin = (params) => {
    return request({
        url: `${prefix}/user/login/smscode`,
        method: 'post',
        data: params,
    })
}



/**
 * 用户登录(密码)
 * @param {*} params
 */
export const passwordlogin = (params) => {
    return request({
        url: `${prefix}/user/login`,
        method: 'post',
        data: params,
    })
}


/**
 * 修改用户密码(免登录)
 * @param {*} params
 */
export const resetpassword = (params) => {
    return request({
        url: `${prefix}/user/reset/password`,
        method: 'post',
        data: params,
    })
}

/**
 * 设置用户密码(鉴权)
 * @param {*} params
 */
export const setpassword = (params) => {
    return request({
        url: `${prefix}/user/set/password`,
        method: 'post',
        data: params,
    })
}




/**
 * 用户权限(列表查询)
 * @param {*} params
 */
export const userpermissions = (params) => {
    return request({
        url: `${prefix}/user/permissions`,
        method: 'get',
        params: params,
    })
}



/**
 * 用户机构切换
 * @param {*} params
 */
export const companychoose = (params) => {
    return request({
        url: `${prefix}/user/company/choose`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户机构(列表查询)
 * @param {*} params
 */
export const companyslist = (params) => {
    return request({
        url: `${prefix}/user/companys`,
        method: 'get',
        data: params,
    })
}

/**
 * 院校列表 
 * @param {*} query
 */
export const collegeList = (query) => {
    return request({
        url: `${prefix}/college/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学院列表 
 * @param {*} query
 */
export const academylist = (query) => {
    return request({
        url: `${prefix}/academy/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 学院专业列表 
 * @param {*} query
 */
export const majorlist = (query) => {
    return request({
        url: `${prefix}/academy/major/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 科目列表
 * @param {*} params
 */
export const subjectlist = (params) => {
    return request({
        url: `${prefix}/subject/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 全部专业列表 
 * @param {*} query
 */
export const allmajorlist = (query) => {
    return request({
        url: `${prefix}/major/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 服务账单(创建)
 * @param {*} params
 */
export const createserviceorder = (params) => {
    return request({
        url: `${prefix}/company/service/order`,
        method: 'post',
        data: params,
    })
}


/**
 * 服务账单(创建)
 * @param {*} params
 */
export const createcompany = (params) => {
    return request({
        url: `${prefix}/company`,
        method: 'post',
        data: params,
    })
}

/**
 * 机构信息(列表查询)
 * @param {*} params
 */
export const companylist = (params) => {
    return request({
        url: `${prefix}/company/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 机构信息(查询详情)
 * @param {*} params
 */
export const companyOne = (params) => {
    return request({
        url: `${prefix}/company`,
        method: 'get',
        params: params,
    })
}

/**
 * 机构信息(批量删除) 
 * @param {*} params
 */
export const deletecompanylist = (params) => {
    return request({
        url: `${prefix}/company/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 机构信息(修改)
 * @param {*} params
 */
export const editcompany = (params) => {
    return request({
        url: `${prefix}/company`,
        method: 'put',
        data: params,
    })
}


/**
 * 字典列表(列表查询)（C端）
 * @param {*} params
 */
export const dictionarylist = (params) => {
    return request({
        url: `${prefix}/crm/dictionary/list`,
        method: 'get',
        params: params,
    })
}




/**
 * 字典名称(查询) （C端）
 * @param {*} query
 */
export const dictionary = (query) => {
    return request({
        url: `${prefix}/crm/dictionary`,
        method: 'get',
        params: query,
    })
}

/**
 * 字典名称(创建)（C端）
 * @param {*} params
 */
export const createdictionary = (params) => {
    return request({
        url: `${prefix}/crm/dictionary`,
        method: 'post',
        data: params,
    })
}

/**
 * 字典名称(删除)（C端）
 * @param {*} params
 */
export const deletedictionary = (params) => {
    return request({
        url: `${prefix}/crm/dictionary`,
        method: 'delete',
        data: params,
    })
}

/**
 * 字典值(创建)（C端）
 * @param {*} params
 */
export const createdictionaryvalue = (params) => {
    return request({
        url: `${prefix}/crm/dictionary/value`,
        method: 'post',
        data: params,
    })
}

/**
 * 字典值(删除) （C端）
 * @param {*} params
 */
export const deletedictionaryvalue = (params) => {
    return request({
        url: `${prefix}/crm/dictionary/value`,
        method: 'delete',
        data: params,
    })
}


/**
 * 服务账单(列表查询)
 * @param {*} params
 */
export const orderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 服务账单(修改)
 * @param {*} params
 */
export const editorderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order`,
        method: 'put',
        data: params,
    })
}

/**
 * 服务账单(批量删除)
 * @param {*} params
 */
export const deleteorderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 用户部门(创建)
 * @param {*} params
 */
export const createuserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户部门(列表查询)
 * @param {*} params
 */
export const userdeptlist = (params) => {
    return request({
        url: `${prefix}/user/dept/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 用户部门(删除)
 * @param {*} params
 */
export const deleteuserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户部门(修改)
 * @param {*} params
 */
export const edituserdept = (params) => {
    return request({
        url: `${prefix}/user/dept`,
        method: 'put',
        data: params,
    })
}

/**
 * 用户角色(创建)
 * @param {*} params
 */
export const createuserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户角色(修改) 
 * @param {*} params
 */
export const edituserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'put',
        data: params,
    })
}

/**
 * 用户角色(删除) 
 * @param {*} params
 */
export const deleteuserrole = (params) => {
    return request({
        url: `${prefix}/user/role`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户角色(列表查询)
 * @param {*} params
 */
export const userrolelist = (params) => {
    return request({
        url: `${prefix}/user/role/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 员工信息(创建)
 * @param {*} params
 */
export const createcompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'post',
        data: params,
    })
}

/**
 * 员工信息(列表查询)
 * @param {*} params
 */
export const companyuserlist = (params) => {
    return request({
        url: `${prefix}/company/user/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 员工信息(删除)
 * @param {*} params
 */
export const deletecompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'delete',
        data: params,
    })
}

/**
 * 员工信息(批量删除) 
 * @param {*} params
 */
export const deletecompanyuserlist = (params) => {
    return request({
        url: `${prefix}/company/user/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 员工信息(修改)
 * @param {*} params
 */
export const editcompanyuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'put',
        data: params,
    })
}


/**
 * 开票申请(列表查询)
 * @param {*} params
 */
export const applylist = (params) => {
    return request({
        url: `${prefix}/company/invoice/apply/list`,
        method: 'get',
        params: params,
    })
}



/**
 * 用户信息(列表查询) 
 * @param {*} params
 */
export const userlist = (params) => {
    return request({
        url: `${prefix}/user/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 用户信息(创建)
 * @param {*} params
 */
export const createuser = (params) => {
    return request({
        url: `${prefix}/user`,
        method: 'post',
        data: params,
    })
}


/**
 * 用户信息(修改) 
 * @param {*} params
 */
export const edituser = (params) => {
    return request({
        url: `${prefix}/user`,
        method: 'put',
        data: params,
    })
}


/**
 * 用户信息(删除)
 * @param {*} params
 */
export const userdelete = (params) => {
    return request({
        url: `${prefix}/user`,
        method: 'delete',
        data: params,
    })
}


/**
 * 机构员工(批量删除) 
 * @param {*} params
 */
export const userlistdelete = (params) => {
    return request({
        url: `${prefix}/user/list`,
        method: 'delete',
        data: params,
    })
}



/**
 * 机构员工(批量删除) 
 * @param {*} params
 */
export const deleteuserlist = (params) => {
    return request({
        url: `${prefix}/company/user/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 机构员工(删除) 
 * @param {*} params
 */
export const deleteuser = (params) => {
    return request({
        url: `${prefix}/company/user`,
        method: 'delete',
        data: params,
    })
}


/**
 * 机构部门(创建) 
 * @param {*} params
 */
export const createcrmdept = (params) => {
    return request({
        url: `${prefix}/crm/dept`,
        method: 'post',
        data: params,
    })
}



/**
 * 机构部门(列表查询)
 * @param {*} params
 */
export const crmdeptlist = (params) => {
    return request({
        url: `${prefix}/crm/dept/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 机构部门(批量删除) 
 * @param {*} params
 */
export const deletecrmdeptlist = (params) => {
    return request({
        url: `${prefix}/crm/dept/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 机构部门(修改)
 * @param {*} params
 */
export const editcrmdept = (params) => {
    return request({
        url: `${prefix}/crm/dept`,
        method: 'put',
        data: params,
    })
}


/**
 * 机构角色(创建)
 * @param {*} params
 */
export const createcrmrole = (params) => {
    return request({
        url: `${prefix}/crm/role`,
        method: 'post',
        data: params,
    })
}



/**
 * 机构角色(列表查询)
 * @param {*} params
 */
export const crmrolelist = (params) => {
    return request({
        url: `${prefix}/crm/role/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 机构角色(修改)
 * @param {*} params
 */
export const editcrmrole = (params) => {
    return request({
        url: `${prefix}/crm/role`,
        method: 'put',
        data: params,
    })
}


/**
 * 机构角色(删除) 
 * @param {*} params
 */
export const deletecrmcrmlist = (params) => {
    return request({
        url: `${prefix}/crm/role`,
        method: 'delete',
        data: params,
    })
}

/**
 * CRM规则(创建) 
 * @param {*} params
 */
export const createcrmrule = (params) => {
    return request({
        url: `${prefix}/crm/rule`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户规则(创建) 
 * @param {*} params
 */
export const createuserrule = (params) => {
    return request({
        url: `${prefix}/user/rule`,
        method: 'post',
        data: params,
    })
}

/**
 * CRM规则(列表查询)
 * @param {*} params
 */
export const crmrulelist = (params) => {
    return request({
        url: `${prefix}/crm/rule/list`,
        method: 'get',
        params: params,
    })
}

/**
 *  CRM规则(批量修改)
 * @param {*} params
 */
export const editcrmrullist = (params) => {
    return request({
        url: `${prefix}/crm/rule/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 用户规则(列表查询) 
 * @param {*} params
 */
export const userrulelist = (params) => {
    return request({
        url: `${prefix}/user/rule/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 用户规则(批量修改) 
 * @param {*} params
 */
export const editrulelist = (params) => {
    return request({
        url: `${prefix}/user/rule/list`,
        method: 'put',
        data: params,
    })
}

/**
 * CRM规则(批量删除) 
 * @param {*} params
 */
export const deletecrmrulelist = (params) => {
    return request({
        url: `${prefix}/crm/rule`,
        method: 'delete',
        data: params,
    })
}


/**
 * 用户规则(删除) 
 * @param {*} params
 */
export const deleteuserrule = (params) => {
    return request({
        url: `${prefix}/user/rule`,
        method: 'delete',
        data: params,
    })
}

/**
 * CRM规则(修改)
 * @param {*} params
 */
export const editcrmrule = (params) => {
    return request({
        url: `${prefix}/crm/rule`,
        method: 'put',
        data: params,
    })
}

/**
 * 用户规则(修改)
 * @param {*} params
 */
export const edituserrule = (params) => {
    return request({
        url: `${prefix}/user/rule`,
        method: 'put',
        data: params,
    })
}



/**
 * 学员信息(创建) 
 * @param {*} params
 */
export const createstudent = (params) => {
    return request({
        url: `${prefix}/company/student`,
        method: 'post',
        data: params,
    })
}

/**
 * 学员信息(列表查询)
 * @param {*} params
 */
export const studentlist = (params) => {
    return request({
        url: `${prefix}/company/student/list`,
        method: 'get',
        params: params,
    })
}


/**
 *  学员信息(查询)
 * @param {*} params
 */
export const studentOne = (params) => {
    return request({
        url: `${prefix}/company/student`,
        method: 'get',
        params: params,
    })
}

/**
 * 学员信息(批量删除)
 * @param {*} params
 */
export const deletestudentlist = (params) => {
    return request({
        url: `${prefix}/company/student/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 学员信息(修改)
 * @param {*} params
 */
export const editstudent = (params) => {
    return request({
        url: `${prefix}/company/student`,
        method: 'put',
        data: params,
    })
}

/**
 * 学员信息(批量修改)
 * @param {*} params
 */
export const editstudentlist = (params) => {
    return request({
        url: `${prefix}/company/student/list`,
        method: 'put',
        data: params,
    })
}
/**
 * 账户信息(列表查询) 
 * @param {*} params
 */
export const accountlist = (params) => {
    return request({
        url: `${prefix}/company/account/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 服务账单(列表查询) 
 * @param {*} params
 */
export const serviceorderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 服务账单(批量删除)
 * @param {*} params
 */
export const deleteserviceorderlist = (params) => {
    return request({
        url: `${prefix}/company/service/order/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 充值记录(列表查询)
 * @param {*} params
 */
export const rechargeorderlist = (params) => {
    return request({
        url: `${prefix}/company/recharge/order/list`,
        method: 'get',
        params: params,
    })
}




/**
 * 充值记录(审核通过)
 * @param {*} params
 */
export const rechargeorderauditdone = (params) => {
    return request({
        url: `${prefix}/company/recharge/order/audit/done`,
        method: 'post',
        data: params,
    })
}

/**
 * 充值记录(审核失败) 
 * @param {*} params
 */
export const rechargeorderauditfail = (params) => {
    return request({
        url: `${prefix}/company/recharge/order/audit/fail`,
        method: 'post',
        data: params,
    })
}



/**
 * 服务订单(创建) 
 * @param {*} params
 */
export const studentorder = (params) => {
    return request({
        url: `${prefix}/company/student/order`,
        method: 'post',
        data: params,
    })
}


/**
 * 服务订单(修改) 
 * @param {*} params
 */
export const editstudentorder = (params) => {
    return request({
        url: `${prefix}/company/student/order`,
        method: 'put',
        data: params,
    })
}


/**
 * 充值记录(列表查询)
 * @param {*} params
 */
export const studentorderlist = (params) => {
    return request({
        url: `${prefix}/company/student/order/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 服务订单(批量删除) 
 * @param {*} params
 */
export const deletestudentorderlist = (params) => {
    return request({
        url: `${prefix}/company/student/order/list`,
        method: 'delete',
        data: params,
    })
}

/**
 * 服务订单(批量修改)
 * @param {*} params
 */
export const editstudentorderlist = (params) => {
    return request({
        url: `${prefix}/company/student/order/list`,
        method: 'put',
        data: params,
    })
}




/**
 * 服务订单跟进记录(列表查询)服务订单
 * @param {*} params
 */
export const companyserviceadvancelist = (params) => {
    return request({
        url: `${prefix}/company/student/service/advance/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 用户关注(创建)
 * @param {*} params
 */
export const createuserfollow = (params) => {
    return request({
        url: `${prefix}/user/follow`,
        method: 'post',
        data: params,
    })
}

/**
 * 用户关注(删除) 
 * @param {*} params
 */
export const deleteuserfollow = (params) => {
    return request({
        url: `${prefix}/user/follow`,
        method: 'delete',
        data: params,
    })
}


/**
 * 参数配置(查询) （C端）
 * @param {*} query
 */
export const querycrmconfigure = (query) => {
    return request({
        url: `${prefix}/crm/configure`,
        method: 'get',
        params: query,
    })
}

/**
 * 参数配置(修改) （C端）
 * @param {*} params
 */
export const editcrmconfigure = (params) => {
    return request({
        url: `${prefix}/crm/configure`,
        method: 'put',
        data: params,
    })
}

/**
 * 配置列表查询 （总端）
 * @param {*} query
 */
export const configureList = (query) => {
    return request({
        url: `${prefix}/configure/list`,
        method: 'get',
        params: query,
    })
}

/**
 * 系统配置（修改）（总端）
 * @param {*} params
 */
export const configureEdit = (params) => {
    return request({
        url: `${prefix}/configure`,
        method: 'put',
        data: params,
    })
}

/**
 * 系统配置（批量修改）（总端）
 * @param {*} params
 */
export const configureListEdit = (params) => {
    return request({
        url: `${prefix}/configure/list`,
        method: 'put',
        data: params,
    })
}

/**
 * 字典名称(创建)（总端）
 * @param {*} params
 */
export const createdictionaryroot = (params) => {
    return request({
        url: `${prefix}/dictionary`,
        method: 'post',
        data: params,
    })
}


/**
 * 字典名称(删除)（总端）
 * @param {*} params
 */
export const deletedictionaryroot = (params) => {
    return request({
        url: `${prefix}/dictionary`,
        method: 'delete',
        data: params,
    })
}

/**
 * 字典列表(列表查询)（总端）
 * @param {*} params
 */
export const dictionarylistroot = (params) => {
    return request({
        url: `${prefix}/dictionary/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 字典名称(查询) （总端）
 * @param {*} query
 */
export const dictionaryroot = (query) => {
    return request({
        url: `${prefix}/dictionary`,
        method: 'get',
        params: query,
    })
}

/**
 * 字典值(创建)（总端）
 * @param {*} params
 */
export const createdictionaryvalueroot = (params) => {
    return request({
        url: `${prefix}/dictionary/value`,
        method: 'post',
        data: params,
    })
}

/**
 * 字典值(删除) （总端）
 * @param {*} params
 */
export const deletedictionaryvalueroot = (params) => {
    return request({
        url: `${prefix}/dictionary/value`,
        method: 'delete',
        data: params,
    })
}


/**
 * 快递(列表查询)
 * @param {*} params
 */
export const expresslist = (params) => {
    return request({
        url: `${prefix}/express/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 开票申请(开票成功) 
 * @param {*} params
 */
export const companyinvoicedone = (params) => {
    return request({
        url: `${prefix}/company/invoice/done`,
        method: 'post',
        data: params,
    })
}

/**
 * 开票申请(审核失败) 
 * @param {*} params
 */
export const companyinvoiceapplyauditfail = (params) => {
    return request({
        url: `${prefix}/company/invoice/apply/audit/fail`,
        method: 'post',
        data: params,
    })
}




/**
 *  师资订单(创建)
 * @param {*} params
 */
export const createteacherorder = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资订单(列表查询) 
 * @param {*} params
 */
export const teacherorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 师资订单(详情) 
 * @param {*} params
 */
export const teacherorderOne = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order`,
        method: 'get',
        params: params,
    })
}



/**
 * 师资订单(详情) 师资派遣
 * @param {*} params
 */
export const dispatchOrderOne = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order`,
        method: 'get',
        params: params,
    })
}

 /**
 * 获取系统匹配师权重值
 * @param {*} params
 */
export const sysAllocationWeightValue = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/sysAllocationWeightValue`,
        method: 'get',
        params: params,
    })
}

/**
 * 师资订单(修改) 
 * @param {*} params
 */
export const editeacherorder = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order`,
        method: 'put',
        data: params,
    })
}

/**
 * 师资订单(批量修改) 
 * @param {*} params
 */
export const editteacherorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资订单(批量删除) 
 * @param {*} params
 */
export const deleteteacherorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 师资订单(修改)
 * @param {*} params
 */
export const editteacherorder = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order`,
        method: 'put',
        data: params,
    })
}

/**
 * 师资订单匹配结果(修改) 
 * @param {*} params
 */
export const matchorderresult = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/result`,
        method: 'post',
        data: params,
    })
}



/**
 * 师资订单跟进记录(列表查询)
 * @param {*} params
 */
export const teacherorderadvancelist = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/advance/list`,
        method: 'get',
        params: params,
    })
}


/**
 *  师资订单跟进记录(创建)
 * @param {*} params
 */
export const createteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/advance`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资订单跟进记录(修改)
 * @param {*} params
 */
export const editteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/advance`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资订单跟进记录(删除)
 * @param {*} params
 */
export const deleteteacherorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/match/order/advance`,
        method: 'delete',
        data: params,
    })
}



/**
 *  师资派遣订单(创建)
 * @param {*} params
 */
export const createteacherdispatchorder = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order`,
        method: 'post',
        data: params,
    })
}
/**
 * 师资派遣(订单) 导出
 * @param {*} params
 */
export const serviceExport = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/serviceExport`,
        method: 'get',
        params: params,
    })
}


/**
 * 师资派遣订单(列表查询) 
 * @param {*} params
 */
export const teacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 师资派遣订单(修改) 
 * @param {*} params
 */
export const editeacherdispatchorder = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单(批量修改) 
 * @param {*} params
 */
export const editteacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/list`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单(批量删除) 
 * @param {*} params
 */
export const deleteteacherdispatchorderlist = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 师资派遣订单跟进记录(列表查询)
 * @param {*} params
 */
export const teacherdispatchorderadvancelist = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/advance/list`,
        method: 'get',
        params: params,
    })
}


/**
 *  师资派遣订单跟进记录(创建)
 * @param {*} params
 */
export const createteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/advance`,
        method: 'post',
        data: params,
    })
}

/**
 * 师资派遣订单跟进记录(修改)
 * @param {*} params
 */
export const editteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/advance`,
        method: 'put',
        data: params,
    })
}


/**
 * 师资派遣订单跟进记录(删除)
 * @param {*} params
 */
export const deleteteacherdispatchorderadvance = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/advance`,
        method: 'delete',
        data: params,
    })
}


/**
 * 师资派遣订单匹配结果(修改) 
 * @param {*} params
 */
export const matchdispatchorderresult = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/result`,
        method: 'post',
        data: params,
    })
}



/**
 *  三方退款(refund) 
 * @param {*} params
 */
export const paymentrefund = (params) => {
    return request({
        url: `${prefix}/payment/refund`,
        method: 'post',
        data: params,
    })
}


/**
 *  外包订单（创建）
 * @param {*} params
 */
export const outsourcing = (params) => {
    return request({
        url: `${prefix}/crmOut/outsourcing`,
        method: 'post',
        data: params,
    })
}


/**
 *  服务记录（查询详情）
 * @param {*} params
 */
export const crmOutrecorddetail = (params) => {
    return request({
        url: `${prefix}/crmOut/record/detail`,
        method: 'post',
        data: params,
    })
}


/**
 *  外包订单（修改）
 * @param {*} params
 */
export const outsourcingedit = (params) => {
    return request({
        url: `${prefix}/crmOut/outsourcing/edit`,
        method: 'post',
        data: params,
    })
}


/**
 *  代签订单（创建）(师资派遣订单)
 * @param {*} params
 */
export const signedBehalf = (params) => {
    return request({
        url: `${prefix}/crmOut/signedBehalf`,
        method: 'post',
        data: params,
    })
}



/**
 *  代签订单（修改）(师资派遣订单)
 * @param {*} params
 */
export const signedBehalfedit = (params) => {
    return request({
        url: `${prefix}/crmOut/signedBehalf/edit`,
        method: 'post',
        data: params,
    })
}


/**
 *  代找订单（创建）
 * @param {*} params
 */
export const crmOuthelpFindcreate = (params) => {
    return request({
        url: `${prefix}/crmOut/helpFind`,
        method: 'post',
        data: params,
    })
}

/**
 *  代找订单（修改）
 * @param {*} params
 */
export const crmOuthelpFindedit = (params) => {
    return request({
        url: `${prefix}/crmOut/helpFind/edit`,
        method: 'post',
        data: params,
    })
}



/**
 *  代找订单（查询详情）
 * @param {*} params
 */
export const helpFinddetail = (params) => {
    return request({
        url: `${prefix}/crmOut/helpFind/detail`,
        method: 'post',
        data: params,
    })
}

/**
 * 机构申请（查询列表）
 * @param {*} params
 */
export const companyapplylist = (params) => {
    return request({
        url: `${prefix}/company/apply/list`,
        method: 'get',
        params: params,
    })
}

/**
 * 机构申请（修改） 
 * @param {*} params
 */
export const companyapplyread = (params) => {
    return request({
        url: `${prefix}/company/apply`,
        method: 'put',
        data: params,
    })
}


/**
 * 机构组（查询列表）
 * @param {*} params
 */
export const grouplist = (params) => {
    return request({
        url: `${prefix}/company/group/list`,
        method: 'get',
        params: params,
    })
}

/**
 *  机构组（创建）
 * @param {*} params
 */
export const companygroupCreate = (params) => {
    return request({
        url: `${prefix}/company/group`,
        method: 'post',
        data: params,
    })
}

/**
 * 机构组（修改）
 * @param {*} params
 */
export const companygroupEdit = (params) => {
    return request({
        url: `${prefix}/company/group`,
        method: 'put',
        data: params,
    })
}


/**
 * 机构组（删除）
 * @param {*} params
 */
export const companygroupDelete = (params) => {
    return request({
        url: `${prefix}/company/group`,
        method: 'delete',
        data: params,
    })
}


/**
 *  班级（查询列表）
 * @param {*} params
 */
export const gradelist = (params) => {
    return request({
        url: `${prefix}/grade/grade/list`,
        method: 'get',
        params: params,
    })
}

/**
 *  班级（查询列表）导出
 * @param {*} params
 */
export const gradeExport = (params) => {
    return request({
        url: `${prefix}/grade/grade/export`,
        method: 'get',
        params: params,
    })
}

/**
 *   班级（创建）
 * @param {*} params
 */
export const gradeCreate = (params) => {
    return request({
        url: `${prefix}/grade/grade`,
        method: 'post',
        data: params,
    })
}


/**
 * 课程计划（查询列表）
 * @param {*} params
 */
export const coursePlanlist = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/list`,
        method: 'get',
        params: params,
    })
}


/**
 *   课程计划（批量创建 文件导入方式）

 * @param {*} params
 */
export const coursePlanImport = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/bulk/byFileImport`,
        method: 'post',
        data: params,
    })
}


/**
 *  课程计划（详情）
 * @param {*} params
 */
export const coursePlanOne = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan`,
        method: 'get',
        params: params,
    })
}

/**
 * 课程计划（修改）
 * @param {*} params
 */
export const coursePlanEdit = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan`,
        method: 'put',
        data: params,
    })
}



/**
 * 课程计划（删除）
 * @param {*} params
 */
export const coursePlanDelete = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan`,
        method: 'delete',
        data: params,
    })
}


/**
 *  老师（查询列表）
 * @param {*} params
 */
export const teacherlist = (params) => {
    return request({
        url: `${prefix}/teacher/teacher/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 老师（创建）
 * @param {*} params
 */
export const teacherCreate = (params) => {
    return request({
        url: `${prefix}/teacher/teacher`,
        method: 'post',
        data: params,
    })
}

/**
 * 老师（修改）
 * @param {*} params
 */
export const teacherEdit = (params) => {
    return request({
        url: `${prefix}/teacher/teacher`,
        method: 'put',
        data: params,
    })
}

/**
 * 老师列表（修改）
 * @param {*} params
 */
export const teacherEditlist = (params) => {
    return request({
        url: `${prefix}/teacher/teacher/list`,
        method: 'put',
        data: params,
    })
}

/**
 *  老师（查询详情）
 * @param {*} params
 */
export const teacherOne = (params) => {
    return request({
        url: `${prefix}/teacher/teacher`,
        method: 'get',
        params: params,
    })
}


/**
 * 班级（查询详情）
 * @param {*} params
 */
export const gradeOne = (params) => {
    return request({
        url: `${prefix}/grade/grade`,
        method: 'get',
        params: params,
    })
}


/**
 * 班级（批量删除）
 * @param {*} params
 */
export const gradeistDelete = (params) => {
    return request({
        url: `${prefix}/grade/grade/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 班级（删除）
 * @param {*} params
 */
export const gradeDelete = (params) => {
    return request({
        url: `${prefix}/grade/grade`,
        method: 'delete',
        data: params,
    })
}


/**
 *  班级（修改）
 * @param {*} params
 */
export const gradeEdit = (params) => {
    return request({
        url: `${prefix}/grade/grade`,
        method: 'put',
        data: params,
    })
}


/**
 *  班级（批量修改）
 * @param {*} params
 */
export const gradelistEdit = (params) => {
    return request({
        url: `${prefix}/grade/grade/list`,
        method: 'put',
        data: params,
    })
}

/**
 *   课时记录（查询列表）
 * @param {*} params
 */
export const lessonLoglist = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/list`,
        method: 'get',
        params: params,
    })
}
/**
 *   课时记录（查询列表）
 * @param {*} params
 */
export const lessslist = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/list`,
        method: 'get',
        params: params,
    })
}
/**
 *   课时登记（导出）
 * @param {*} params
 */
 export const lessonExport = (params) => {
    return request({
        url: `${prefix}/company/teacher/dispatch/order/export`,
        method: 'get',
        params: params,
    })
}

 /*   生成机构账单课时记录（查询列表）
 * @param {*} params
 */
export const lesson = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/selectByPost/list`,
        method: 'post',
        data: params,
    })
}

/**
 *   课时记录（查询详情）
 * @param {*} params
 */
export const lessonLogOne = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog`,
        method: 'get',
        params: params,
    })
}

/**
 *   课时记录（学生列表）（老师管理的综合评分用到）
 * @param {*} params
 */
export const lessonLogStudentList = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/student/list`,
        method: 'get',
        params: params,
    })
}


/**
 *   课时记录（确认课时）

 * @param {*} params
 */
export const confirmLesson = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/confirmLesson`,
        method: 'post',
        data: params,
    })
}


/**
 *    课时记录（课程反馈 学管师）

 * @param {*} params
 */
export const courseFeedback = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/courseFeedback`,
        method: 'post',
        data: params,
    })
}

/**
 *    课程计划（创建）

 * @param {*} params
 */
export const coursePlanCreate = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan`,
        method: 'post',
        data: params,
    })
}


/**
 *    课程计划（批量创建）

 * @param {*} params
 */
export const coursePlanbulk = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/bulk`,
        method: 'post',
        data: params,
    })
}


/**
 *     课程计划（审核）

 * @param {*} params
 */
export const coursePlanCheck = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/check`,
        method: 'post',
        data: params,
    })
}


/**
 * 课时记录（修改）
 * @param {*} params
 */
export const lessonLogEdit = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog`,
        method: 'put',
        data: params,
    })
}


/**
 * 课时记录（批量修改）
 * @param {*} params
 */
export const lessonLogListEdit = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/list`,
        method: 'put',
        data: params,
    })
}



/**
 *   任务大厅（任务查询列表）
 * @param {*} params
 */
export const tasklist = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task/list`,
        method: 'get',
        params: params,
    })
}

/**
 *  任务大厅（任务创建）

 * @param {*} params
 */
export const taskCreate = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task`,
        method: 'post',
        data: params,
    })
}

/**
 * 任务大厅（批量删除）
 * @param {*} params
 */
export const tasklistDelete = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task/list`,
        method: 'delete',
        data: params,
    })
}


/**
 *   任务大厅（查询详情）
 * @param {*} params
 */
export const taskOne = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task`,
        method: 'get',
        params: params,
    })
}


/**
 * 任务大厅（修改）
 * @param {*} params
 */
export const taskEdit = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task`,
        method: 'put',
        data: params,
    })
}


/**
 *  任务大厅（审核）

 * @param {*} params
 */
export const taskcheck = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/task/check`,
        method: 'post',
        data: params,
    })
}


/**
 *  任务大厅（提交记录查询列表）
 * @param {*} params
 */
export const taskloglist = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/commitLog/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 任务大厅（提交记录审核）

 * @param {*} params
 */
export const commitLogcheck = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/commitLog/check`,
        method: 'post',
        data: params,
    })
}



/**
 * 任务大厅（提交记录批量审核）

 * @param {*} params
 */
export const commitLogchecklistCheck = (params) => {
    return request({
        url: `${prefix}/seniorWork/taskRoom/commitLog/check/list`,
        method: 'post',
        data: params,
    })
}


/**
 *  通用操作记录（查询列表）
 * @param {*} params
 */
export const commonHandleLogList = (params) => {
    return request({
        url: `${prefix}/commonHandleLog/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 通用操作记录（创建）

 * @param {*} params
 */
export const commonHandleLogCreate = (params) => {
    return request({
        url: `${prefix}/commonHandleLog`,
        method: 'post',
        data: params,
    })
}


/**
 * 课时记录操作记录（修改）
 * @param {*} params
 */
export const handleLogEdit = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/handleLog`,
        method: 'put',
        data: params,
    })
}


/**班级同步（创建）
 * 
 * @param {*} params
 */
export const gradeSync = (params) => {
    return request({
        url: `${prefix}/crmOut/gradeSync`,
        method: 'post',
        data: params,
    })
}

/**班级同步（修改）
 * 
 * @param {*} params
 */
export const gradeSyncEdit = (params) => {
    return request({
        url: `${prefix}/crmOut/gradeSync/edit`,
        method: 'post',
        data: params,
    })
}


/**课时记录操作记录（创建）
 * 
 * @param {*} params
 */
export const handleLogCreate = (params) => {
    return request({
        url: `${prefix}/lesson/lessonLog/handleLog`,
        method: 'post',
        data: params,
    })
}


/**
 *  老师匹配订单（查询列表）
 * @param {*} params
 */
export const upOrderList = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order`,
        method: 'put',
        data: params,
    })
}

/**
 *  老师匹配订单（修改列表）
 * @param {*} params
 */
export const taList = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/list`,
        method: 'get',
        params: params,
    })
}

/**
 *  老师匹配订单（查询详情）
 * @param {*} params
 */
export const taOne = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order`,
        method: 'get',
        params: params,
    })
}


/** 老师匹配订单（创建）
 * 
 * @param {*} params
 */
export const createTa = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order`,
        method: 'post',
        data: params,
    })
}

/** 老师匹配订单（ 取消订单）
 * 
 * @param {*} params
 */
export const cancelOrdereTa = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/cancelOrder`,
        method: 'post',
        data: params,
    })
}


/**
 *  老师匹配记录（查询列表）
 * @param {*} params
 */
export const taRecordList = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/record/list`,
        method: 'get',
        params: params,
    })
}


/**
 * 老师匹配订单（修改）
 * @param {*} params
 */
export const editTa = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order`,
        method: 'put',
        data: params,
    })
}



/**
 *  院校年份资料（查询列表）
 * @param {*} params
 */
export const materiallist = (params) => {
    return request({
        url: `${prefix}/collegeYear/material/list`,
        method: 'get',
        params: params,
    })
}



/**
 * 院校年份资料（创建）
 * @param {*} params
 */
export const materialCreate = (params) => {
    return request({
        url: `${prefix}/collegeYear/material`,
        method: 'post',
        data: params,
    })
}


/**
 *  院校年份资料（查询详情）
 * @param {*} params
 */
export const materialOne = (params) => {
    return request({
        url: `${prefix}/collegeYear/material`,
        method: 'get',
        params: params,
    })
}


/**
 * 院校年份资料（修改）
 * @param {*} params
 */
export const materialEdit = (params) => {
    return request({
        url: `${prefix}/collegeYear/material`,
        method: 'put',
        data: params,
    })
}


/**
 * 院校年份资料（批量删除）
 * @param {*} params
 */
export const materiallistDelete = (params) => {
    return request({
        url: `${prefix}/collegeYear/material/list`,
        method: 'delete',
        data: params,
    })
}


/**
 * 老师匹配记录（创建）
 * @param {*} params
 */
export const taRecordCreate = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/record`,
        method: 'post',
        data: params,
    })
}


/**
 * 老师匹配记录（读消息）
 * @param {*} params
 */
export const taRead = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/record/readMsg`,
        method: 'post',
        data: params,
    })
}


/**
 * 老师匹配订单（确认关联老师）
 * @param {*} params
 */
export const taOrderConfirm = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/confirmTeacherRef`,
        method: 'post',
        data: params,
    })
}


/**
 * 老师匹配记录（删除）
 * @param {*} params
 */
export const taRecordDelete = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/record`,
        method: 'delete',
        data: params,
    })
}


/**
 *   匹配订单 查询我的签单接口 
 * @param {*} params
 */
export const taSignOrderList = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/signOrder/list`,
        method: 'get',
        params: params,
    })
}

/**s
 *   机构订单价格（查询列表）
 * @param {*} params
 */
export const orderPriceList = (params) => {
    return request({
        url: `${prefix}/company/orderPrice/list`,
        method: 'get',
        params: params,
    })
}

/**s
 *  机构订单价格（创建）
 * @param {*} params
 */
export const orderPriceCreate = (params) => {
    return request({
        url: `${prefix}/company/orderPrice`,
        method: 'post',
        data: params,

    })
}


/**
 *   机构订单价格（查询详情）
 * @param {*} params
 */
export const orderPriceOne = (params) => {
    return request({
        url: `${prefix}/company/orderPrice`,
        method: 'get',
        params: params,
    })
}


/**s
 *  机构订单价格（修改）
 * @param {*} params
 */
export const orderPriceEdit = (params) => {
    return request({
        url: `${prefix}/company/orderPrice`,
        method: 'put',
        data: params,

    })
}

/**
 * 机构订单价格（批量删除）
 * @param {*} params
 */
export const orderPriceDeleteList = (params) => {
    return request({
        url: `${prefix}/company/orderPrice/list`,
        method: 'delete',
        data: params,
    })
}


/**
 *   课时 计划导出
 * @param {*} params
 */
export const coursePlanExport = (params) => {
    return request({
        url: `${prefix}/lesson/coursePlan/export`,
        method: 'get',
        params: params,
    })
}


/**
 *   师资 订单导出接口 
 * @param {*} params
 */
export const taOrderExport = (params) => {
    return request({
        url: `${prefix}/teacherAllocation/order/export`,
        method: 'get',
        params: params,
    })
}



/**
 *  学管师服务记录（创建）
 * @param {*} params
 */
export const learnManageRecordCreate = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord`,
        method: 'post',
        data: params,

    })
}


/**
 *   学管师服务记录（查询列表）
 * @param {*} params
 */
export const learnManageRecordList = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/list`,
        method: 'get',
        params: params,
    })
}

/**
 *   学管师服务记录（查询详情）
 * @param {*} params
 */
export const learnManageRecordOne = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord`,
        method: 'get',
        params: params,
    })
}


/**s
 *  学管师服务记录（修改）
 * @param {*} params
 */
export const learnManageRecordEdit = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord`,
        method: 'put',
        data: params,
    })
}

/**
 * 学管师服务记录（批量删除）
 * @param {*} params
 */
export const learnManageRecordDeleteList = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/list`,
        method: 'delete',
        data: params,
    })
}

/**s
 *  学管师服务记录（读艾特消息）
 * @param {*} params
 */
export const learnManageRecordAitread = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/aitRead`,
        method: 'put',
        data: params,
    })
}


/**
 *  学管师服务记录（批量创建）
 * @param {*} params
 */
export const learnManageRecordCreateList = (params) => {
    return request({
        url: `${prefix}/lesson/learnManageRecord/bulk`,
        method: 'post',
        data: params,

    })
}


/**
 *   课时   学员管理(导出)
 * @param {*} params
 */
export const companystudentexport = (params) => {
    return request({
        url: `${prefix}/company/student/export`,
        method: 'get',
        params: params,
    })
}

/**
 *   课时  延时
 * @param {*} params
 */
export const addConfirmTimeBackHandle = (params) => {
    return request({
        url: `${prefix}/sharePlatform/findOrderHandle/addConfirmTimeBackHandle`,
        method: 'post',
        data: params,
    })
}







